import { Dispatch, SetStateAction } from "react";

import magicPenIntro from "../../../assets/videos/magicPenIntro.mp4";
import talkAlongCardsIntro from "../../../assets/videos/talkAlongCardsIntro.mp4";
import qaCardsIntro from "../../../assets/videos/qaCardsIntro.mp4";
import closeIcon from "../../../assets/images/demoGame/closeIcon.png";
import Text from "../../../components/ui/Text";
import Touchable from "../../../components/ui/Touchable";
import { GameType } from "../../../types";

export const titleMapping: {
  [key in GameType]: string;
} = {
  magicPenHome: "",
  magicPen: "Magic Pen",
  talkAlongCards: "Talk Along Card",
  QACards: "Q&A Card",
};

export const videoMapping: {
  [key in GameType]: string;
} = {
  magicPenHome: magicPenIntro,
  magicPen: magicPenIntro,
  talkAlongCards: talkAlongCardsIntro,
  QACards: qaCardsIntro,
};

export default function DemoGameVideo({
  gameType,
  setShouldOpenIntroVideo,
}: {
  gameType: GameType;
  setShouldOpenIntroVideo: Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <div className="absolute left-0 top-0 z-50 flex h-screen w-screen flex-col bg-black">
      <div className="relative flex h-11 w-full shrink-0 items-center justify-center">
        <Touchable
          className="absolute left-5 top-2.5 size-6"
          onClick={() => setShouldOpenIntroVideo(false)}
        >
          <img src={closeIcon} alt="closeIcon" />
        </Touchable>

        <Text className="text-[11px] font-medium text-white">
          {titleMapping[gameType]}
        </Text>
      </div>

      <div className="flex w-full flex-1 items-center justify-center">
        <video
          className="max-w-screen"
          style={{ maxHeight: "calc(100vh - 44px)" }}
          controls
          autoPlay
          controlsList="nodownload"
          onContextMenu={(e) => e.preventDefault()}
        >
          <source src={videoMapping[gameType]} />
        </video>
      </div>
    </div>
  );
}
