import { Dispatch, SetStateAction } from "react";

import blueCloseIcon from "../../../assets/images/demoGame/blueCloseIcon.png";
import qrCode from "../../../assets/images/demoGame/qrCode.png";
import Text from "../../../components/ui/Text";
import Touchable from "../../../components/ui/Touchable";

export default function DemoGameQRCode({
  setShouldOpenQRCodeModel,
}: {
  setShouldOpenQRCodeModel: Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <div className="absolute left-0 top-0 z-50 flex h-screen w-screen items-center justify-center bg-black/40">
      <div className="flex w-[298px] flex-col gap-1 rounded-3xl bg-white">
        <div className="relative flex h-11 w-full items-center justify-center">
          <Touchable
            className="absolute left-2.5 top-2.5 flex size-6 items-center justify-center rounded-full"
            style={{ background: "rgba(223, 241, 255, 0.85)" }}
            onClick={() => setShouldOpenQRCodeModel(false)}
          >
            <img src={blueCloseIcon} alt="blueCloseIcon" className="size-4" />
          </Touchable>

          <Text className="text-[11px] font-medium text-[#1D1D1D]">
            Download the app
          </Text>
        </div>

        <div className="flex w-full flex-col items-center gap-5 px-5 pb-5">
          <div
            className="w-fit rounded-lg p-3"
            style={{ boxShadow: "2px 2px 8px rgba(35, 45, 74, 0.10)" }}
          >
            <img src={qrCode} alt="qrCode" className="size-[108px]" />
          </div>

          <Text className="text-[11px] font-medium leading-4 text-[#545A6A]">
            Scan the QR code to download the club app.
          </Text>
        </div>
      </div>
    </div>
  );
}
