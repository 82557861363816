import Text from "../../../components/ui/Text";
import Touchable from "../../../components/ui/Touchable";
import cn from "../../../utils/cn";

export default function DemoGameButton({
  text,
  onClick,
  image,
  className,
  blue = false,
}: {
  text: string;
  onClick: () => void;
  image?: string;
  className?: string;
  blue?: boolean;
}) {
  const bgColor = blue ? "bg-[#008EFA]" : "bg-white";
  const textColor = blue ? "text-white" : "text-[#008EFA]";

  return (
    <Touchable
      className={cn(
        bgColor,
        "flex h-10 items-center gap-1.5 rounded-full border-2 border-solid border-[#D0D5DD] px-6",
        className,
      )}
      onClick={onClick}
    >
      <Text lg className={cn(textColor, "text-[14px]")}>
        {text}
      </Text>

      {image ? <img src={image} alt="button" className="size-6" /> : null}
    </Touchable>
  );
}
